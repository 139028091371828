import React, {Component} from 'react';
import {
    CloseCircleOutlined,
    CloseOutlined,
    CloseSquareOutlined,
    SyncOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
} from '@ant-design/icons';
import {Icon} from 'src/library/components';
import {Menu} from 'antd';
import {DraggableTabsBar} from 'src/library/components';
import config from 'src/commons/config-hoc';
import {ContextMenu} from 'src/library/components';
import './style.less';
import left from './arrow_left.svg'
import right from "./arrow_right.svg"

@config({
    router: true,
    connect: state => ({
        dataSource: state.system.tabs,
        keepAlive: state.system.keepAlive,
        currentPath:state.menu.currentPath
    }),
})
export default class PageTabs extends Component {
    state = {
        contextVisible: false,
        contextEvent: null,
        contextMenu: '',
        flag:-1//flag 为当前点击tab在menu数组中位于第几个
    };

    constructor(props){
        super(props)
        this.scrollw = 0;
        this.arr = props.dataSource;
        this.click = false;//是否点击左右按钮
    }

    handleClose = (item,e,index) => {
        this.click = false;
        if(index + 1 == this.state.flag || this.state.flag == -1){
            this.setState({flag: index > 0 ? index -1 : index + 1})
        }
        const {path: targetPath} = item;
        this.props.action.system.closeTab(targetPath);
        setTimeout(()=>{
            window.dispatchEvent(new Event("resize"));
        },80)
    };

    handleSortEnd = ({oldIndex, newIndex}) => {
        const dataSource = [...this.props.dataSource];

        // 元素移动
        dataSource.splice(newIndex, 0, dataSource.splice(oldIndex, 1)[0]);

        this.props.action.system.setTabs(dataSource);
    };

    handleClick = (item,a,index) => {
        this.setState({flag : index + 1})//获取当前点击tab所处于第几个
        this.click = false;
        this.props.action.menu.setCurrentOpenPath(null);

        const separator = '/iframe_page_/';
        let path = item.path;
        if (path.indexOf(separator) !== -1) {
            const url = window.encodeURIComponent(path.split(separator)[1]);
            path = `${separator}${url}`;
        }
        this.props.history.push(path);
        setTimeout(()=>{
            window.dispatchEvent(new Event("resize"));
        },80)
        
    };


    handleRightClick = (e, tab) => {
        e.preventDefault();

        const contextMenu = this.renderContextMenu(tab);

        this.setState({
            contextVisible: true,
            contextEvent: {clientX: e.clientX, clientY: e.clientY},
            contextMenu,
        });
    };

    renderContextMenu = (tab) => {
        const {dataSource, keepAlive} = this.props;
        const disabledClose = dataSource.length === 1;
        const tabIndex = dataSource.findIndex(item => item.path === tab.path);
        const disabledCloseLeft = tabIndex === 0;
        const disabledCloseRight = tabIndex === dataSource.length - 1;

        return (
            <Menu
                selectable={false}
                onClick={({key: action}) => this.handleMenuClick(action, tab.path)}
            >
                {keepAlive ? (
                    [
                        <Menu.Item key="refresh">
                            <SyncOutlined/> 刷新
                        </Menu.Item>,
                        <Menu.Item key="refreshAll">
                            <SyncOutlined/> 刷新全部
                        </Menu.Item>,
                        <Menu.Divider key="divider"/>,
                    ]
                ) : null}
                <Menu.Item key="close" disabled={disabledClose}>
                    <CloseOutlined/> 关闭
                </Menu.Item>
                <Menu.Item key="closeOthers" disabled={disabledClose}>
                    <CloseCircleOutlined/> 关闭其他
                </Menu.Item>
                <Menu.Item key="closeAll" disabled={disabledClose}>
                    <CloseSquareOutlined/> 关闭所有
                </Menu.Item>
                <Menu.Item key="closeLeft" disabled={disabledCloseLeft}>
                    <VerticalLeftOutlined/> 关闭左侧
                </Menu.Item>
                <Menu.Item key="closeRight" disabled={disabledCloseRight}>
                    <VerticalRightOutlined/> 关闭右侧
                </Menu.Item>
            </Menu>
        );
    };

    handleMenuClick = (action, targetPath) => {
        const {action: {system}} = this.props;

        if (action === 'refresh') system.refreshTab(targetPath);
        if (action === 'refreshAll') system.refreshAllTab();
        if (action === 'close') system.closeTab(targetPath);
        if (action === 'closeOthers') system.closeOtherTabs(targetPath);
        if (action === 'closeAll') system.closeAllTabs();
        if (action === 'closeLeft') system.closeLeftTabs(targetPath);
        if (action === 'closeRight') system.closeRightTabs(targetPath);
    };

    handle_Info(menuList,father){
        var max = (menuList.length+1) * 140 - father.offsetWidth;
        if(this.scrollw == max || Math.abs(this.scrollw -max) < 140){//滚动到最右侧，不进行滚动计算
            return;
        }else{
            this.scrollw = this.scrollw + 140*2;
        }
    }
    handle_Click(tag){
        this.setState({flag : -1})
        this.click = true;
        this.props.action.menu.setCurrentOpenPath(null);

        var father = document.getElementsByClassName('draggable-tabs-bar-root')[0];
        var menuList = document.getElementsByClassName('draggable-tabs-bar-horizontal-item');
        var scrollWidth = tag < 0 ? 0 : father.scrollLeft;
        if(menuList.length * 140 >= scrollWidth + father.offsetWidth){
            if(tag > 0){//右按钮
               this.handle_Info(menuList,father)
                
            }else{//左按钮
                if(this.scrollw == 0){//滚动到最左侧，不进行滚动计算
                    return;
                }
                if(this.scrollw <= 2*140){//剩余滚动距离不足以完成一次滚动时
                    this.scrollw = 0;
                }else if(this.scrollw > 2*140){
                    this.scrollw = this.scrollw - 2 * 140;
                }
            }
        }
    }
    handle_getIndex(){
        const {dataSource} = this.props;
        dataSource.map((item,index)=>{
            if(item.active) this.setState({flag : index + 1});//首次加载时，获取点击tab的位置
        })
    }
    componentDidMount(){
        this.handle_getIndex()
    }
    componentWillUpdate(){
        // this.handle_getIndex()
    }
    componentDidUpdate(){
        const { dataSource ,currentPath} = this.props;
        const { flag } = this.state
        var father = document.getElementsByClassName('draggable-tabs-bar-root')[0];
        var diff = Math.floor(father.offsetWidth / 140);
        if(currentPath){
            var current = -1;
            dataSource.map((item,index)=>{
                if(item.path == currentPath){
                    current = index;
                }
            })
            this.scrollw = (current  - diff + 2) * 140 ;
        }else{

            if(this.click){//左右按钮点击
                father.scrollTo(this.scrollw,0);
            }

            if((dataSource.length -1)* 140 > father.offsetWidth && !this.click){//自动滚动
                if(flag + 1 < diff){//点击当前窗口可容纳tab中，其中一个，无需滚动
                    this.scrollw = 0;
                }else if(diff < flag  <= dataSource.length){//当前点击为最后一个;点击，除倒数第一个
                    this.scrollw = (flag  - diff + 1) * 140 ;
                }
                this.click = false;
            }
        }
        father.scrollTo(this.scrollw,0);
    }
    render() {
        const {dataSource, width} = this.props;
        const {contextVisible, contextEvent, contextMenu} = this.state;
        const currentTab = dataSource.find(item => item.active);
        const tabsBarDataSource = []
        dataSource.forEach(item => {
            let {text: tabTitle, path, icon} = item;
            let title = tabTitle;

            if (typeof tabTitle === 'object' && tabTitle.text) title = tabTitle.text;

            if (tabTitle?.icon) icon = tabTitle.icon;

            if (icon) title = <div style={{flex: 1, textAlign: 'center'}}><Icon type={icon} style={{marginRight: 4}}/>{title}</div>;


            if(path.indexOf('login')===-1){
                tabsBarDataSource.push({
                    key: path,
                    title,
                    closable: true,
                    ...item,
                })
            }

           
        });

        return (
            <div styleName="root">
                <ContextMenu
                    visible={contextVisible}
                    onChange={(contextVisible) => this.setState({contextVisible})}
                    event={contextEvent}
                    content={contextMenu}
                />
                <img src={left}  style={{
                    position: 'absolute',
                    left: '0px',
                    top: '8%',
                    border: 'none',
                    textAlign: 'right',
                    padding: '0px',
                    height:'30px'}} alt="" onClick={()=>{
                            this.handle_Click(-1)
                    }}/>
                <DraggableTabsBar
                    dataSource={tabsBarDataSource}
                    itemWrapper={(itemJsx, item, wrapperClassName) => {
                        return (
                            <div
                                className={wrapperClassName}
                                onContextMenu={(e) => this.handleRightClick(e, item)}
                            >
                                {itemJsx}
                            </div>
                        );
                    }}
                    onSortEnd={this.handleSortEnd}
                    onClose={this.handleClose}
                    onClick={this.handleClick}
                    activeKey={currentTab?.path}
                    parentWidth={width}
                />
                    <img src={right}  style={{
                    position: 'absolute',
                    right: '0px',
                    top: '8%',
                    border: 'none',
                    textAlign: 'right',
                    padding: '0px',
                    height:'30px'}} alt="" onClick={()=>{
                        this.handle_Click(1)
                    }}/>
            </div>
        );
    }
}
