import React, { Component } from "react";
import { Modal } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import classNames from 'classnames';
import "./style.less";

export default class TableComponent extends Component {

    state = {
        // imgList: [], // 图片数组
        showDown: true, // 显示下载按钮
        activeIndex: 1,
        previewVisible: false, // 显示预览
        previewImage: null, // 预览图片地址
        style:false,
    }

    // componentDidMount() {
        // const { value = [], showDown = true, showNum = 1 } = this.props;
        // let imgList = Array.isArray(value) ? value : [value];
        // if (imgList.length > showNum) {
        //     imgList.splice(0, showNum);
        // }
        // this.setState({
        //     showDown,
        //     imgList
        // })
    // }

    onPreview = url => {
        this.setState({
            previewImage: url,
            previewVisible: true
        })
    };

    handleCancel = () => this.setState({ previewVisible: false });

    onMouseEnter(index) {
        this.setState({ activeIndex: index })
    }
    onMouseLeave() {
        this.setState({ activeIndex: -1 })
    }

    render() {
        const { showDown, activeIndex, previewVisible, previewImage } = this.state;
        const { value ,style} = this.props;
        let imgList = Array.isArray(value) ? value : [value];
        return (
            <div className="table-image" style={{...style}}>
                <div className={classNames(`imgGroup`)} >
                    {imgList && imgList.map((item, index) => {
                        return <div className='imgItem' key={`${index}`}>
                            <div className='imgBox'
                                onMouseEnter={() => { this.onMouseEnter(index) }}
                                onMouseLeave={() => { this.onMouseLeave(index) }}
                            >
                                <img className='img' src={item} alt="" />
                                <div className={`operBox ${index === activeIndex ? 'active' : ''}`}>
                                    <EyeOutlined className='icon' onClick={() => this.onPreview(item)} style={{ fontSize: 14 }} />
                                    {showDown && <DownloadOutlined className='icon' onClick={() => {
                                        let a = document.createElement("a");
                                        let event = new MouseEvent("click");
                                        a.download = parseInt(Math.random() * 1000) || "photo";
                                        a.href = item;
                                        a.target = '_blank';
                                        a.dispatchEvent(event);
                                    }} style={{ fontSize: 14 }} />}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                >
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
            </div >
        );
    }
};