import React, { Component } from 'react';
import { Switch } from "antd";

const STATE = {
    CHECKED: 1,
    UNCHECKED: 0
}

export default class SwicthComponent extends Component {

    render() {
        const { value, onChange } = this.props;

        return (<Switch checked={value === STATE.CHECKED} onChange={value => {
            console.log(value);
            typeof onChange === "function" && onChange(value ? STATE.CHECKED : STATE.UNCHECKED)
        }
        } />)
    }
}