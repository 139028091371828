import React, { Component } from "react";
import { Upload, notification, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import reqwest from "reqwest";
import { HOST } from "src/commons/api";
import { REQUEST_CODE } from "src/constants";
import DraggableUploadList from "../draggable-uploadList";
import "./style.less";

export default class TableComponent extends Component {

    state = {
        showMode: false, // 查看模式
        showDown: true, // 显示下载按钮
        maxNum: 1, // 图片上传最大数量
        className: null,
        loading: false, // 显示loading
        fileList: [], // 上传文件数组
        imgList: [], // 图片数组
        previewVisible: false, // 显示预览
        previewImage: null // 预览图片地址
    }

    componentDidMount() {
        const { value = [], showMode, showDown = true, maxNum = 1, className = "group" ,onRef} = this.props;
        if (typeof onRef === "function") onRef(this);

        let imgList = Array.isArray(value) ? value : [value];
        let fileList = this.initFileList(imgList);
        this.setState({
            showMode,
            showDown,
            maxNum,
            imgList,
            fileList,
            className
        })
    }
    handle_Refresh(newNum){
        const {value = [], showMode, showDown = true, maxNum = 1, className = "group" , onRef } = this.props;
        if (typeof onRef === "function") onRef(this);

        let imgList = Array.isArray(value) ? value : [value];
        let fileList = this.initFileList(imgList);
        this.setState({
            showMode,
            showDown,
            maxNum: newNum,
            imgList,
            fileList,
            className
        })
    }

    initFileList = imgList => {
        return imgList.map((val, index) => ({
            uid: index,
            name: "image-" + index,
            status: "done",
            url: val
        }));
    };

    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (typeof onChange === "function") {
            onChange(changedValue);
        }
    };

    handleUpload = file => {
        let {env} = this.props
        this.setState({ loading: true })
        const formData = new FormData();
        formData.append("file", file);

        let _env = env == 'prod'? 'https://hbdapi.jshbc2017.com' : HOST

        console.log('[handleUpload]', _env)

        reqwest({
            url: `${_env}/third/Common/UploadFile`,
            method: "post",
            processData: false,
            data: formData,
            success: res => {
                const { code = 0 } = res;
                if (code === REQUEST_CODE.SUCCESS) {
                    this.onUploadEnd(res)
                }
                this.setState({ loading: false })
            },
            error: () => {
                notification.success({
                    message: `上传失败，请重试`,
                    duration: 2,
                });
                this.setState({ loading: false })
            }
        });
        return false;
    };

    onUploadEnd = res => {

        const { imgList } = this.state;
        const { data = {} } = res;
        const { urlList = [] } = data;

        imgList.push(...urlList);
        const fileList = this.initFileList(imgList);

        this.setState({
            imgList,
            fileList
        }, () => this.triggerChange(imgList))
    }

    handleRemove = file => {

        const { showMode, imgList } = this.state;
        if (showMode) return;

        let { uid } = file;

        imgList.splice(uid, 1);
        const fileList = this.initFileList(imgList);

        this.setState({
            imgList,
            fileList
        }, () => this.triggerChange(imgList))
    };

    handlePreview = async file => {
        this.setState({
            previewImage: file.url || file.preview || file.thumbUrl,
            previewVisible: true
        })
    };

    handleCancel = () => this.setState({ previewVisible: false });

    onSortEnd = fileList => {
        let imgList = fileList.map(val => val.url);
        fileList = this.initFileList(imgList);

        this.setState({
            imgList,
            fileList
        }, () => this.triggerChange(imgList))
    };

    render() {
        const { loading, showMode, showDown, fileList, previewVisible, previewImage, maxNum, className } = this.state;
        const props = {
            showUploadList: false,
            listType: "picture-card",
            className: "avatar-uploader",
            accept: "image/*", // image/*,video/*
            multiple: true, // 是否支持多选文件，ie10+ 支持。开启后按住 ctrl 可选择多个文件
            beforeUpload: file => this.handleUpload(file),
        };
        return (
            <div className="form-upload">
                <DraggableUploadList
                    showDown={showDown}
                    showMode={showMode}
                    fileList={fileList}
                    onRemove={file => this.handleRemove(file)}
                    onPreview={this.handlePreview.bind(this)}
                    onSortEnd={this.onSortEnd.bind(this)}
                    className={className}>
                    {
                        !showMode && fileList.length < maxNum && <Upload {...props}>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div className="ant-upload-text">上传</div>
                        </Upload>
                    }
                </DraggableUploadList>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                >
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
            </div>
        );
    }
};
