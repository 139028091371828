/*
 * @Author: your name
 * @Date: 2021-06-04 12:23:42
 * @LastEditTime: 2021-06-07 10:53:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GroupShopping_admin_web\src\layouts\header\logo\index.js
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import logo from "./logo.svg";
import SOURCE from '../../../versionConfig'
import "./style.less";

export default class Logo extends Component {
    static propTypes = {
        min: PropTypes.bool,
    };
    static defaultProps = {
        logo: logo,
        title: "React Web",
        min: false,
    };

    render() {
        // const {min, title, ...others} = this.props;
        return (
            <div styleName="logo">
                <img src={SOURCE.APP_LOGO} alt="logo" style={{maxHeight:'28px',width:'100%'}}/>
                {/* <h1 {...others} className={min ? 'title-hide' : ''}>{title}</h1> */}
            </div>
        );
    }
}
