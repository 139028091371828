
let env =  process.env.NODE_ENV
let app_name = process.env.APP_NAME

console.log('env', env, 'app_name', app_name, process.env.APP_NAME)

let HOST = ''
let HOST_MOCK = 'http://yapi.51qiji.net/mock/89'
// let HOST_MOCK = 'http://192.168.0.185:3000/mock/20'

if(env === 'production'){
    HOST = 'https://api.17qj.co'
}else if(env === 'development'){
    HOST = 'https://xjapitest.17qj.co'
}else if(env === 'test') {
    HOST = 'https://xjapitest.17qj.co'
}

console.log('[api]HOST:',HOST)


export  {HOST,HOST_MOCK}