import React, { Component } from "react";
import {
    EyeOutlined,
    DownloadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";

import {
    arrayMove,
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";
import classNames from "classnames";
import "./style.less";

const SortableItem = SortableElement((props) => {
    const {
        item,
        onMouseEnter,
        onMouseLeave,
        onRemove,
        onPreview,
        showMode,
        onFresh,
        activeIndex,
        showDown,
    } = props;

    return (
        <div className="imgItem globle">
            <div
                className="imgBox"
                onMouseEnter={() => {
                    onMouseEnter(item.uid);
                }}
                onMouseLeave={() => {
                    onMouseLeave(item.uid);
                }}
            >
                <img className="img" src={item.url || item.thumbUrl} alt="" />
                <div
                    className={`operBox ${
                        item.uid === activeIndex ? "active" : ""
                    }`}
                >
                    <EyeOutlined
                        className="icon"
                        onClick={() => onPreview(item)}
                    />
                    {showDown && (
                        <DownloadOutlined
                            className="icon"
                            onClick={() => {
                                let a = document.createElement("a");
                                let event = new MouseEvent("click");
                                a.download =
                                    parseInt(Math.random() * 1000) || "photo";
                                a.href = item.url || item.thumbUrl;
                                a.target = "_blank";
                                a.dispatchEvent(event);
                            }}
                        />
                    )}
                    {!showMode && (
                        <DeleteOutlined
                            className="icon"
                            onClick={() => {
                                onRemove(item);
                                onFresh();
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

const SortableContainerList = SortableContainer((props) => {
    const {
        dataSource,
        isSorting,
        onMouseEnter,
        onMouseLeave,
        onPreview,
        onRemove,
        showMode,
        children,
        className,
        ...others
    } = props;

    return (
        <div
            className={classNames(`imgGroup ${className}`, {
                sorting: isSorting,
            })}
        >
            {dataSource &&
                dataSource.map((item, index) => {
                    return (
                        <SortableItem
                            key={"key_" + index}
                            item={item}
                            index={index}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onPreview={onPreview}
                            onRemove={onRemove}
                            showMode={showMode}
                            {...others}
                        ></SortableItem>
                    );
                })}
            {!showMode && <div style={{ width: 106 }}>{children}</div>}
        </div>
    );
});

export default class DraggableUploadList extends Component {
    state = {
        itemLength: 0,
        isSorting: false,
    };

    onSortStart = (info, event) => {
        const { showMode } = this.props;
        this.setState({ isSorting: !showMode || true });
    };

    onSortEnd = (info, event) => {
        this.setState({ isSorting: false });
        let { newIndex, oldIndex } = info;

        const { fileList, onSortEnd } = this.props;
        let _fileList = arrayMove([...fileList], oldIndex, newIndex);

        if (onSortEnd) {
            onSortEnd(_fileList);
        }
    };

    onFresh() {
        this.setState({});
    }
    onMouseEnter(index) {
        this.setState({ activeIndex: index });
    }
    onMouseLeave() {
        this.setState({ activeIndex: -1 });
    }

    render() {
        const {
            fileList,
            showMode,
            onPreview,
            onRemove,
            children,
            showDown,
            className,
        } = this.props;
        const { isSorting, activeIndex } = this.state;

        const props = {
            isSorting,
            dataSource: fileList,
            activeIndex,
            onFresh: this.onFresh.bind(this),
            onSortEnd: this.onSortEnd,
            onSortStart: this.onSortStart,
            onMouseEnter: this.onMouseEnter.bind(this),
            onMouseLeave: this.onMouseLeave.bind(this),
            onPreview,
            onRemove,
            showMode,
            showDown,
            axis: "x",
            distance: 1,
            ref: "component",
            children,
            className,
        };

        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                ref={(node) => (this.container = node)}
            >
                <SortableContainerList {...props} />
            </div>
        );
    }
}
