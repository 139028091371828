import React, { Component } from 'react';
import { Upload } from "src/library/components";

export default class UploadComponent extends Component {

    render() {
        const { value, onChange } = this.props;

        return (<Upload value={value} showMode={false} maxNum={1} onChange={value => typeof onChange === "function" && Array.isArray(value) && onChange(value.join(","))} />)
    }
}