import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'src/library/components';
import {Menu} from 'antd';
import {renderNode} from 'src/library/utils/tree-utils';
import Link from '../../page-link';
import './style.less';



const award = () => ( <svg t="1636638246576" class="icon" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" width="1.2em" height="1.2em">
    <defs><style type="text/css"></style></defs>
    <path d="M833.79 175.95h-41.3v-30.96c0-43.66-34.2-77.86-77.87-77.86H309.57c-43.67 0-77.87 34.2-77.87 77.86v33.98h-41.29c-30.1 0-53.68 23.58-53.68 53.68v75.57c0 58.19 40.77 109.32 95.1 124.67 0.31 6.95 1.48 15.52 6 24.52l2.51 5.03C295.48 598.13 386.37 690.68 478.82 709v189.14h-94.41c-16.29 0-29.5 13.21-29.5 29.5s13.21 29.5 29.5 29.5h246.31c16.29 0 29.5-13.21 29.5-29.5s-13.21-29.5-29.5-29.5h-92.91V710.29c93.48-14.53 185.94-106.66 245.52-246.8l3.01-6.03c4.5-8.99 5.68-17.51 6-24.43 55.02-15.04 95.11-65.51 95.11-124.81v-78.59c0.01-30.1-23.56-53.68-53.66-53.68zM230.45 368.48c-20.49-12.63-34.72-35.54-34.72-60.27v-70.26h34.72v130.53z m499.36 70.26c-55.53 130.5-140.99 214.81-217.71 214.81-78.28 0-165.68-86.12-217.51-214.31l-3.71-7.46c-0.19-0.89-0.19-3.66-0.19-5.67V144.98c0-10.94 7.94-18.87 18.88-18.87h405.06c10.94 0 18.88 7.93 18.88 18.87v281.13c0 2.03 0 4.8 0.09 4.94l-3.79 7.69z m98.66-130.52c0 25.8-13.89 48.54-34.72 60.81V234.94h34.72v73.28z" fill="#666666" p-id="3472"></path>
</svg>)


const SubMenu = Menu.SubMenu;

export default class SideMenu extends Component {
    static propTypes = {
        dataSource: PropTypes.array,    // 菜单数据
        theme: PropTypes.string,        // 主题
        collapsed: PropTypes.bool,      // 是否收起
        openKeys: PropTypes.array,      // 打开菜单keys
        selectedKeys: PropTypes.array,  // 选中菜单keys
        onOpenChange: PropTypes.func,   // 菜单打开关闭时触发
    };

    static defaultProps = {
        dataSource: [],
        theme: 'dark',
        collapsed: false,
        openKeys: [],
        selectedKeys: [],
        onOpenChange: () => true,
        handle_Open:() => {}
    };

    handleOpenChange = (openKeys) => {
        this.props.onOpenChange(openKeys);
    };

    renderMenus() {
        const {dataSource , handle_Open} = this.props;

        if (dataSource && dataSource.length) {
            return renderNode(dataSource, (item, children) => {
                const {
                    key,
                    path,
                    text,
                    icon,
                    iconComponent,
                    target,
                    url,
                } = item;

                let title = <span>{text}</span>;

                if (icon) title = <span><Icon type={icon}/><span>{text}</span></span>;
                if (iconComponent) {
                    if(iconComponent == 'award'){
                        title = <span><Icon component={award}/><span>{text}</span></span>;
                    }
                }

                if (children) {
                    return (
                        <SubMenu key={key} title={title}>
                            {children}
                        </SubMenu>
                    );
                }

                return (
                    <Menu.Item key={key} onClick={()=>{typeof handle_Open === 'function' && handle_Open(path)}}>
                        {target ? (
                            <a href={url} target={target}>
                                {title}
                            </a>
                        ) : (
                            <Link to={{
                                pathname: path,
                                state: {from: 'menu'},
                            }}>
                                {title}
                            </Link>
                        )}
                    </Menu.Item>
                );
            });
        }
        return null;
    }

    render() {
        let {theme, collapsed, openKeys, selectedKeys} = this.props;
        const menuProps = collapsed ? {} : {
            openKeys,
        };

        return (
            <div styleName="side-menu">
                <Menu
                    {...menuProps}
                    selectedKeys={selectedKeys}
                    mode="inline"
                    theme={theme}
                    inlineCollapsed={collapsed}
                    onOpenChange={this.handleOpenChange}
                >
                    {this.renderMenus()}
                </Menu>
            </div>
        );
    }
}
