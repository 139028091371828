let app_name = process.env.APP_NAME

// 荟当家
let SOURCE = {
    APP_LOGO :require('./image/huidangjia.svg'),
    APP_HOME_SVG: require('./image/hdj_homesvg.svg'),
}

if(app_name == 'xj'){
    SOURCE.APP_LOGO = require('./image/xijiang.svg')
    SOURCE.APP_HOME_SVG = ''
}

export default SOURCE