// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login'
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login'
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/activity/new/list',
        keepAlive: true,
    },
    {
        path: '/activity/new/skuEdit/:actProductId/:productId',
        keepAlive: true,
    },
    {
        path: '/activity/topic/goods/:actId',
        keepAlive: true,
    },
    {
        path: '/activity/topic',
        keepAlive: true,
    },
    {
        path: '/after-sale/after-sale/check/:serviceApplyId',
        keepAlive: true,
    },
    {
        path: '/after-sale/refund/check/:refundId',
        keepAlive: true,
    },
    {
        path: '/after-sale/after-sale/edit/:serviceApplyId',
        keepAlive: true,
    },
    {
        path: '/after-sale/refund/edit/:refundId',
        keepAlive: true,
    },
    {
        path: '/after-sale/list',
        keepAlive: true,
    },
    {
        path: '/after-sale/reissue-goods/:serviceApplyId',
        keepAlive: true,
    },
    {
        path: '/new-after-sale/list',
        keepAlive: true,
    },
    {
        path: '/new-after-sale-oper/list',
        keepAlive: true,
    },
    {
        path: '/auditing/check/:refundId',
        keepAlive: true,
    },
    {
        path: '/auditing/list',
        keepAlive: true,
    },
    {
        path: '/reissue-goods/check/:exchangeId',
        keepAlive: true,
    },
    {
        path: '/reissue-goods/list',
        keepAlive: true,
    },
    {
        path: '/power_starff',
        keepAlive: true,
    },
    {
        path: '/supplier_staff',
        keepAlive: true,
    },
    {
        path: '/consumer/addEditCheck/:businessId',
        keepAlive: true,
    },
    {
        path: '/consumer/check',
        keepAlive: true,
    },
    {
        path: '/consumer/list',
        keepAlive: true,
    },
    {
        path: '/consumer/subordinate/:userId',
        keepAlive: true,
    },
    {
        path: '/financial_commission',
        keepAlive: true,
    },
    {
        path: '/financial_income',
        keepAlive: true,
    },
    {
        path: '/financial_incomeOrder',
        keepAlive: true,
    },
    {
        path: '/financial_payment',
        keepAlive: true,
    },
    {
        path: '/financial_refundOrder',
        keepAlive: true,
    },
    {
        path: '/financial_withdrawal',
        keepAlive: true,
    },
    {
        path: '/gen/uploadImg',
        keepAlive: true,
    },
    {
        path: '/goods/category',
        keepAlive: true,
    },
    {
        path: '/goods/category/sub/list/:parentId',
        keepAlive: true,
    },
    {
        path: '/goods/edit_file/:productId',
        keepAlive: true,
    },
    {
        path: '/goods/file',
        keepAlive: true,
    },
    {
        path: '/goods/skuEdit_file/:productId',
        keepAlive: true,
    },
    {
        path: '/goods/add',
        keepAlive: true,
    },
    {
        path: '/goods/goods/collocation/:productId',
        keepAlive: true,
    },
    {
        path: '/goods/edit/:productId',
        keepAlive: true,
    },
    {
        path: '/goods/list',
        keepAlive: true,
    },
    {
        path: '/goods/skuEdit/:productId',
        keepAlive: true,
    },
    {
        path: '/goods/spec',
        keepAlive: true,
    },
    {
        path: '/goods/stock',
        keepAlive: true,
    },
    {
        path: '/goods/stock/log/:productId/:skuId/:stockQuantity',
        keepAlive: true,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/operational/editBanner/:id',
        keepAlive: true,
    },
    {
        path: '/operational/banner',
        keepAlive: true,
    },
    {
        path: '/operational/couponItems',
        keepAlive: true,
    },
    {
        path: '/operational/couponItems/skuEdit/:productId',
        keepAlive: true,
    },
    {
        path: '/operational/goods/check/:productId',
        keepAlive: true,
    },
    {
        path: '/operational/goods/collocation/:productId',
        keepAlive: true,
    },
    {
        path: '/operational/goods',
        keepAlive: true,
    },
    {
        path: '/operational/goods/skuEdit/:productId',
        keepAlive: true,
    },
    {
        path: '/operational/goodsActEdit/:id',
        keepAlive: true,
    },
    {
        path: '/operational/goodsAct',
        keepAlive: true,
    },
    {
        path: '/operational/goodsAct/skuEdit/:actId',
        keepAlive: true,
    },
    {
        path: '/operational/material',
        keepAlive: true,
    },
    {
        path: '/operational/scenario',
        keepAlive: true,
    },
    {
        path: '/operational/scenario/skuEdit/:productId',
        keepAlive: true,
    },
    {
        path: '/OperationManager/ArticleList',
        keepAlive: true,
    },
    {
        path: '/OperationManager/DecorateDesign/:pageId/:pageType',
        keepAlive: true,
    },
    {
        path: '/OperationManager/ThemeList',
        keepAlive: true,
    },
    {
        path: '/importRecord',
        keepAlive: true,
    },
    {
        path: '/orderManage',
        keepAlive: true,
    },
    {
        path: '/orderManage/newSendGoods/:orderNo/:tag',
        keepAlive: true,
    },
    {
        path: '/orderManage/orderDetail/:orderId',
        keepAlive: true,
    },
    {
        path: '/RecommandManager/List',
        keepAlive: true,
    },
    {
        path: '/statistics/activity',
        keepAlive: true,
    },
    {
        path: '/statistics/award',
        keepAlive: true,
    },
    {
        path: '/statistics/goodsAct',
        keepAlive: true,
    },
    {
        path: '/statistics/goods',
        keepAlive: true,
    },
    {
        path: '/statistics/orderscore',
        keepAlive: true,
    },
    {
        path: '/statistics/overview',
        keepAlive: true,
    },
    {
        path: '/statistics/receiveRecording',
        keepAlive: true,
    },
    {
        path: '/statistics/rfmmodel',
        keepAlive: true,
    },
    {
        path: '/statistics/shareRecording',
        keepAlive: true,
    },
    {
        path: '/statistics/skustatistics',
        keepAlive: true,
    },
    {
        path: '/statistics/topicstatistics',
        keepAlive: true,
    },
    {
        path: '/statistics/verificationRecording',
        keepAlive: true,
    },
    {
        path: '/supplier_after_sale_address/:supplierId',
        keepAlive: true,
    },
    {
        path: '/supplier_adjust',
        keepAlive: true,
    },
    {
        path: '/supplier_create',
        keepAlive: true,
    },
    {
        path: '/supplier_payment',
        keepAlive: true,
    },
    {
        path: '/supplier_examrecord',
        keepAlive: true,
    },
    {
        path: '/supplier_record',
        keepAlive: true,
    },
    {
        path: '/supplier_settle',
        keepAlive: true,
    },
    {
        path: '/supplier_manager/addEditSupplier/:supplierId/:readOnly',
        keepAlive: true,
    },
    {
        path: '/supplier_manager',
        keepAlive: true,
    }
];

// 页面路由配置
export default [
    {
        path: '/activity/new/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/active/new/index.jsx'),
    },
    {
        path: '/activity/new/skuEdit/:actProductId/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/active/new/skuEdit.jsx'),
    },
    {
        path: '/activity/topic/goods/:actId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/activity/topic/goodManage/goodsManage.jsx'),
    },
    {
        path: '/activity/topic',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/activity/topic/index.jsx'),
    },
    {
        path: '/after-sale/after-sale/check/:serviceApplyId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/checkAfterSale.jsx'),
    },
    {
        path: '/after-sale/refund/check/:refundId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/checkRefund.jsx'),
    },
    {
        path: '/after-sale/after-sale/edit/:serviceApplyId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/editAfterSale.jsx'),
    },
    {
        path: '/after-sale/refund/edit/:refundId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/editRefund.jsx'),
    },
    {
        path: '/after-sale/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/index.jsx'),
    },
    {
        path: '/after-sale/reissue-goods/:serviceApplyId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/afterSale/reissueGoods.jsx'),
    },
    {
        path: '/new-after-sale/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/newAfterSale/index.jsx'),
    },
    {
        path: '/new-after-sale/detail/:afterSalesId/:type',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/newAfterSaleDetail/index.jsx'),
    },
    {
        path: '/new-after-sale-oper/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/newAfterSaleOper/index.jsx'),
    },
    {
        path: '/auditing/check/:refundId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/refundAuditing/check.jsx'),
    },
    {
        path: '/auditing/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/refundAuditing/index.jsx'),
    },
    {
        path: '/reissue-goods/check/:exchangeId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/reissueGoods/check.jsx'),
    },
    {
        path: '/reissue-goods/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/afterSaleService/reissueGoods/index.jsx'),
    },
    {
        path: '/power_role',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/roleManage/index.jsx'),
    },
    {
        path: '/power_role/powerSet/:role_name/:roleId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/roleManage/powerSet.jsx'),
    },
    {
        path: '/power_starff/addEdit/:flag/:managerId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/starffManage/addEdit.jsx'),
    },
    {
        path: '/power_starff',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/starffManage/index.jsx'),
    },
    {
        path: '/supplier/addEdit/:flag/:managerId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/supplierManage/addEdit.jsx'),
    },
    {
        path: '/supplier_staff',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/authorityManage/supplierManage/index.jsx'),
    },
    {
        path: '/charts',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/chart/index.jsx'),
    },
    {
        path: '/consumer/addEditCheck/:businessId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/consumer/addEditCheck.jsx'),
    },
    {
        path: '/consumer/check',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/consumer/check.jsx'),
    },
    {
        path: '/consumer/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/consumer/index.jsx'),
    },
    {
        path: '/consumer/subordinate/:userId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/consumer/subordinate.jsx'),
    },
    {
        path: '/financial_commission',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/commission/index.jsx'),
    },
    {
        path: '/financial_income',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/income/index.jsx'),
    },
    {
        path: '/financial_incomeOrder',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/incomeOrder/index.jsx'),
    },
    {
        path: '/financial_payment',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/payment/index.jsx'),
    },
    {
        path: '/financial_refundOrder',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/refundOrder/index.jsx'),
    },
    {
        path: '/financial_withdrawal',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/financial/withdrawal/index.jsx'),
    },
    {
        path: '/gen/drag',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/exam/drag.jsx'),
    },
    {
        path: '/gen/formElement',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/exam/formElement.jsx'),
    },
    {
        path: '/gen/map',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/exam/map.jsx'),
    },
    {
        path: '/gen/table',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/exam/table.jsx'),
    },
    {
        path: '/gen',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/index.jsx'),
    },
    {
        path: '/gen/uploadImg',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/gen/uploadImg.jsx'),
    },
    {
        path: '/goods/category',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/category/index.jsx'),
    },
    {
        path: '/goods/category/sub/list/:parentId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/category/sub/index.jsx'),
    },
    {
        path: '/goods/edit_file/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/file/edit.jsx'),
    },
    {
        path: '/goods/file',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/file/index.jsx'),
    },
    {
        path: '/goods/skuEdit_file/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/file/skuEdit.jsx'),
    },
    {
        path: '/goods/add',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/goods/add.jsx'),
    },
    {
        path: '/goods/goods/collocation/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/goods/collocation/goodsManage.jsx'),
    },
    {
        path: '/goods/edit/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/goods/edit.jsx'),
    },
    {
        path: '/goods/list',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/goods/index.jsx'),
    },
    {
        path: '/goods/skuEdit/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/goods/skuEdit.jsx'),
    },
    {
        path: '/goods/spec',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/spec/index.jsx'),
    },
    {
        path: '/goods/stock',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/stock/index.jsx'),
    },
    {
        path: '/goods/stock/log/:productId/:skuId/:stockQuantity',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/goods/stock/log.jsx'),
    },
    {
        path: '/',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/home/index.jsx'),
    },
    {
        path: '/hooks-test',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/hooks-test/index.jsx'),
    },
    {
        path: '/react-use',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/hooks-test/ReactUse.jsx'),
    },
    {
        path: '/hooks-callback',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/hooks-test/UseCallback.jsx'),
    },
    {
        path: '/hooks-effect',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/hooks-test/UseEffect.jsx'),
    },
    {
        path: '/use-state',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/hooks-test/UseState.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/login/index.jsx'),
    },
    {
        path: '/operational/editBanner/:id',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/banner/edit.jsx'),
    },
    {
        path: '/operational/banner',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/banner/index.jsx'),
    },
    {
        path: '/operational/couponItems',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/couponItems/index.jsx'),
    },
    {
        path: '/operational/couponItems/skuEdit/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/couponItems/skuEdit.jsx'),
    },
    {
        path: '/operational/goods/check/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goods/check.jsx'),
    },
    {
        path: '/operational/goods/collocation/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goods/collocation/goodsManage.jsx'),
    },
    {
        path: '/operational/goods',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goods/index.jsx'),
    },
    {
        path: '/operational/goods/skuEdit/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goods/skuEdit.jsx'),
    },
    {
        path: '/operational/goodsActEdit/:id',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goodsAct/editGoodsAct.jsx'),
    },
    {
        path: '/operational/goodsAct',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goodsAct/index.jsx'),
    },
    {
        path: '/operational/goodsAct/skuEdit/:actId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/goodsAct/skuEdit.jsx'),
    },
    {
        path: '/operational/material',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/material/index.jsx'),
    },
    {
        path: '/operational/scenario',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/scenario/index.jsx'),
    },
    {
        path: '/operational/scenario/skuEdit/:productId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operational/scenario/skuEdit.jsx'),
    },
    {
        path: '/OperationManager/ArticleList',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operationManager/ArticleList/index.jsx'),
    },
    {
        path: '/OperationManager/DecorateDesign/:pageId/:pageType',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operationManager/DecorateDesign/index.jsx'),
    },
    {
        path: '/OperationManager/ThemeList',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/operationManager/ThemeList/index.jsx'),
    },
    {
        path: '/importRecord',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/orderManage/importRecord/index.jsx'),
    },
    {
        path: '/orderManage',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/orderManage/orderTable/index.jsx'),
    },
    {
        path: '/orderManage/newSendGoods/:orderNo/:tag',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/orderManage/orderTable/newSendGoods.jsx'),
    },
    {
        path: '/orderManage/orderDetail/:orderId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/orderManage/orderTable/orderDetail.jsx'),
    },
    {
        path: '/orderManage/sendCoods/:orderNo/:tag',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/orderManage/orderTable/sendGoods.jsx'),
    },
    {
        path: '/RecommandManager/List',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/recommandManager/index.jsx'),
    },
    {
        path: '/statistics/activity',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/activity.jsx'),
    },
    {
        path: '/statistics/award',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/award.jsx'),
    },
    {
        path: '/statistics/goodsAct',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/goodAct.jsx'),
    },
    {
        path: '/statistics/goods',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/index.jsx'),
    },
    {
        path: '/statistics/orderscore',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/orderscore/index.jsx'),
    },
    {
        path: '/statistics/overview',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/overview/index.jsx'),
    },
    {
        path: '/statistics/receiveRecording',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/receiveRecording/index.jsx'),
    },
    {
        path: '/statistics/rfmmodel',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/rfmmodel/index.jsx'),
    },
    {
        path: '/statistics/shareRecording',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/shareRecording/index.jsx'),
    },
    {
        path: '/statistics/skustatistics',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/skustatistics/index.jsx'),
    },
    {
        path: '/statistics/topicstatistics',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/topicstatistics/index.jsx'),
    },
    {
        path: '/statistics/verificationRecording',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/statisticsData/verificationRecording/index.jsx'),
    },
    {
        path: '/supplier_after_sale_address/:supplierId',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/addressManage/index.jsx'),
    },
    {
        path: '/supplier_adjust',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/adjustSheet/index.jsx'),
    },
    {
        path: '/supplier_create',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/createRecord/index.jsx'),
    },
    {
        path: '/supplier_record/record_detail/:id',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/detail/recordDetail.jsx'),
    },
    {
        path: '/supplier_settle/settle_detail/:id',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/detail/settleDetail.jsx'),
    },
    {
        path: '/supplier_payment',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/paymentQuery/index.jsx'),
    },
    {
        path: '/supplier_examrecord',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/recordExam/index.jsx'),
    },
    {
        path: '/supplier_record',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/recordManage/index.jsx'),
    },
    {
        path: '/supplier_settle',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/settleManage/index.jsx'),
    },
    {
        path: '/supplier_manager/addEditSupplier/:supplierId/:readOnly',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/supplierManage/addEditSupplier.jsx'),
    },
    {
        path: '/supplier_manager',
        component: () => import('/var/jenkins_home/workspace/yx-manage/src/pages/supplier/supplierManage/index.jsx'),
    }
];
    