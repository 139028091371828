import React, { Component } from 'react';

export default class TextComponent extends Component {

    render() {
        const { value, options, before, after } = this.props;
        
        let show = (options && options[value]) || value;
        if (before) show = before + show;
        if (after) show += after;

        return (<p style={{ margin: 0 }}>{show}</p>)
    }
}