import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.less';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'

export default class ToolBar extends Component {
    static propTypes = {
        right: PropTypes.bool,
    };

    static defaultProps = {
        right: false,
    };

    render() {
        const { style = {} , tool_btn_style = {}, right, children, ...others } = this.props;

        if (right && !style.justifyContent) {
            style.justifyContent = 'flex-end';
        }

        return (
            <div className="tool-bar-root" style={style} {...others}>
                {
                    Array.isArray(children) && children.map((item, index) => {
                        if (item.icon  === 'plus') {
                             item.icon = <PlusOutlined />
                        }
                        return <Button style={{display:item.visible ? item.visible:'block',...tool_btn_style}} icon={item.icon} type={item.type ? item.type:'primary'} size={'middle'} onClick={() => item.onClick()}>{item.text}</Button>
                    })
                }
            </div>
        );
    }
}
