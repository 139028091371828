/*
 * @Author: your name
 * @Date: 2021-12-17 15:39:47
 * @LastEditTime: 2021-12-20 11:10:25
 * @LastEditors: Please set LastEditors
 * @Description: 公共的api请求，和getMenus放到一起
 * @FilePath: \GroupShopping_admin_web\src\library\utils\common-request.js
 */
import { ajax } from 'src/commons/ajax';

export default function api_getSupplier(){
    let supplierList = [];
    return ajax.post('/oper/sys/supplier/querySupplierList', {pageNum:1,pageSize:10000}, { errorTip: false })
    .then((res)=>{
        const { code , msg ,data} = res;
        if(code !== 20000){
            // message.error(msg ? msg :'获取供应商列表失败');
            return [];
        }
        const { list } = data;
        list.forEach((item,index)=>{
            var obj = {};
            obj.value = item.supplierId;
            obj.label = item.supplierName;
            supplierList.push(obj);
        })
        return supplierList
    })
}