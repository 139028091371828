import { ajax } from 'src/commons/ajax';
// import {PLATFORM_INDUSTRY_CODE, FOOD_INDUSTRY_CODE, SHOP_INDUSTRY_CODE} from 'src/constants/index'
let env = process.env.NODE_ENV

// const award = require('./svg/award.svg')
const developmentCode = 0



const deve_menuList = [
    { code: developmentCode, key: 'gen', text: '开发工具', icon: 'code', order: 60099 },
    { code: developmentCode, key: 'gen-index', text: '代码生成', icon: 'code', parentKey: 'gen', path: '/gen', order: 60099 },
    { code: developmentCode, key: 'gen-formElement', text: '表单示例', icon: 'code', parentKey: 'gen', path: '/gen/formElement', order: 60199 },
    { code: developmentCode, key: 'gen-table', text: '表格示例', icon: 'code', parentKey: 'gen', path: '/gen/table', order: 60199 },
    { code: developmentCode, key: 'gen-map', text: '地图', icon: 'code', parentKey: 'gen', path: '/gen/map', order: 60199 },
    { code: developmentCode, key: 'gen-drag', text: '拖拽', icon: 'code', parentKey: 'gen', path: '/gen/drag', order: 60199 },
    { code: developmentCode, key: 'gen-drag', text: '上传线上图片', icon: 'code', parentKey: 'gen', path: '/gen/uploadImg', order: 60199 },
]
const menusList = [

    // {code:'100',  key: 'member', text: '会员管理', icon: 'usergroup-add',  order: 60098 },
    // {code:'10001',  key: 'member-list', parentKey: 'member', text: '会员列表', icon: 'idcard', path: '/member/index', order: 100},
    // {code:'10002',  key: 'member-level', parentKey: 'member', text: '会员等级管理', icon: 'apartment', path: '/member/level', order: 100},
    {
        code: "400",
        key: "statistics",
        text: "运营数据",
        icon: "bar-chart",
        order: 60098,
    },
    {
        code: "402",
        key: "overview",
        parentKey: "statistics",
        text: "总体销售概览",
        icon: "pie-chart",
        path: "/statistics/overview",
        order: 100,
    },

    {
        code: "401",
        key: "good",
        parentKey: "statistics",
        text: "商品数据看板",
        icon: "area-chart",
        path: "/statistics/goods",
        order: 100,
    },
    {
        code: "410",
        key: "goodAct",
        parentKey: "statistics",
        text: "活动商品看板",
        icon: "instagram",
        path: "/statistics/goodsAct",
        order: 100,
    },

    // goodsAct
    {
        code: "405",
        key: "topicstatistics",
        parentKey: "statistics",
        text: "主题场景看板",
        icon: "bank",
        path: "/statistics/topicstatistics",
        order: 100,
    },
    {
        code: "403",
        key: "rfmmodel",
        parentKey: "statistics",
        text: "用户RFM模型",
        icon: "solution",
        path: "/statistics/rfmmodel",
        order: 100,
    },
    {
        code: "404",
        key: "skustatistics",
        parentKey: "statistics",
        text: "用户商品分析",
        icon: "idcard",
        path: "/statistics/skustatistics",
        order: 100,
    },
    {
        code: "406",
        key: "orderscore",
        parentKey: "statistics",
        text: "用户订单评分",
        icon: "codepen",
        path: "/statistics/orderscore",
        order: 100,
    },
    {
        code: "407",
        key: "shareRecording",
        parentKey: "statistics",
        text: "优惠券分享记录",
        icon: "book",
        path: "/statistics/shareRecording",
        order: 100,
    },
    {
        code: "408",
        key: "receiveRecording",
        parentKey: "statistics",
        text: "优惠券领取记录",
        icon: "alert",
        path: "/statistics/receiveRecording",
        order: 100,
    },
    {
        code: "409",
        key: "verificationRecording",
        parentKey: "statistics",
        text: "优惠券核销记录",
        icon: "block",
        path: "/statistics/verificationRecording",
        order: 100,
    },
    {
        code: "1000",
        key: "operational",
        text: "平台运营",
        icon: "unordered-list",
        order: 60098,
    },
    {
        code: "1002",
        key: "operational-goods",
        parentKey: "operational",
        text: "商品运营",
        icon: "table",
        path: "/operational/goods",
        order: 100,
    },
    {
        code: "1013",
        key: "operational-banner",
        parentKey: "operational",
        text: "banner管理",
        icon: "Book",
        path: "/operational/banner",
        order: 100,
    },
    {
        code: "1014",
        key: "operational-goods-act",
        parentKey: "operational",
        text: "活动管理",
        icon: "bell",
        path: "/operational/goodsAct",
        order: 100,
    },
    {
        code: "1007",
        key: "operational-topic",
        parentKey: "operational",
        text: "主题场景",
        icon: "file-text",
        path: "/activity/topic",
        order: 100,
    },
    {
        code: "1008",
        key: "operational-scenario",
        parentKey: "operational",
        text: "分类商品",
        icon: "tags",
        path: "/operational/scenario",
        order: 100,
    },
    {
        code: "1009",
        key: "ThemeManager",
        parentKey: "operational",
        text: "主题管理",
        icon: "like",
        path: "/OperationManager/ThemeList",
        order: 100,
    },
    {
        code: "1010",
        key: "ArticleManager",
        parentKey: "operational",
        text: "文章管理",
        icon: "container",
        path: "/OperationManager/ArticleList",
        order: 100,
    },
    {
        code: "1011",
        key: "RecommandManager",
        parentKey: "operational",
        text: "坑位管理",
        icon: "coffee",
        path: "/RecommandManager/List",
        order: 100,
    },
    {
        code: "1012",
        key: "CouponItems",
        parentKey: "operational",
        text: "优惠券推荐商品",
        icon: "dollarCircle",
        path: "/operational/couponItems",
        order: 100,
    },

    {
        code: "200",
        key: "order",
        text: "订单管理",
        icon: "sliders",
        order: 60098,
    },
    {
        code: "201",
        key: "orderManage",
        parentKey: "order",
        text: "订单列表",
        icon: "radar-chart",
        path: "/orderManage",
        order: 60098,
    },
    {
        code: "202",
        key: "importRecord",
        parentKey: "order",
        text: "导入记录",
        icon: "heat-map",
        path: "/importRecord",
        order: 60098,
    },
    {
        code: "300",
        key: "service",
        text: "售后管理",
        icon: "box-plot",
        order: 60098,
    },
    {
        code: "301",
        key: "new-after-sale-list",
        parentKey: "service",
        text: "售后审核",
        icon: "stock",
        path: "/new-after-sale/list",
        order: 60098,
    },
    {
        code: "302",
        key: "new-after-sale-oper-list",
        parentKey: "service",
        text: "售后处理",
        icon: "block",
        path: "/new-after-sale-oper/list",
        order: 60098,
    },
    {
        code: "100",
        key: "goods",
        text: "商品管理",
        icon: "shopping-cart",
        order: 60098,
    },
    {
        code: "101",
        key: "goods-list",
        parentKey: "goods",
        text: "商品列表",
        icon: "tablet",
        path: "/goods/list",
        order: 100,
    },
    {
        code: "103",
        key: "goods-stock",
        parentKey: "goods",
        text: "库存管理",
        icon: "tag",
        path: "/goods/stock",
        order: 100,
    },
    {
        code: "102",
        key: "goods-category",
        parentKey: "goods",
        text: "类目管理",
        icon: "tags",
        path: "/goods/category",
        order: 100,
    },
    {
        code: "104",
        key: "goods-spec",
        parentKey: "goods",
        text: "商品规格",
        icon: "star",
        path: "/goods/spec",
        order: 100,
    },
    {
        code: "105",
        key: "goods-file",
        parentKey: "goods",
        text: "商品归档",
        icon: "save",
        path: "/goods/file",
        order: 100,
    },
    {
        code: "600",
        key: "supplier",
        text: "供应商管理",
        icon: "team",
        order: 60098,
    },
    {
        code: "601",
        key: "supplier_manager",
        parentKey: "supplier",
        text: "供应商管理",
        icon: "user-add",
        path: "/supplier_manager",
        order: 100,
    },
    {
        code: "602",
        key: "supplier_adjust",
        parentKey: "supplier",
        text: "调整单管理",
        icon: "copy",
        path: "/supplier_adjust",
        order: 100,
    },
    {
        code: "603",
        key: "supplier_settle",
        parentKey: "supplier",
        text: "查看结算单",
        icon: "cloud",
        path: "/supplier_settle",
        order: 100,
    },
    {
        code: "604",
        key: "supplier_record",
        parentKey: "supplier",
        text: "查看对账单",
        icon: "carryOut",
        path: "/supplier_record",
        order: 100,
    },
    {
        code: "605",
        key: "supplier_create",
        parentKey: "supplier",
        text: "生成对账单",
        icon: "plus",
        path: "/supplier_create",
        order: 100,
    },
    {
        code: "606",
        key: "supplier_examrecord",
        parentKey: "supplier",
        text: "对账单审核",
        icon: "file-search",
        path: "/supplier_examrecord",
        order: 100,
    },
    {
        code: "607",
        key: "supplier_payment",
        parentKey: "supplier",
        text: "供应商货款查询",
        icon: "bell",
        path: "/supplier_payment",
        order: 100,
    },
    {
        code: "608",
        key: "starff",
        parentKey: "supplier",
        text: "供应商账号管理",
        icon: "user-add",
        path: "/supplier_staff",
        order: 100,
    },

    {
        code: "700",
        key: "financial",
        text: "财务管理",
        icon: "money-collect",
        order: 60098,
    },
    {
        code: "701",
        key: "financial_withdrawal",
        parentKey: "financial",
        text: "提现记录",
        icon: "read",
        path: "/financial_withdrawal",
        order: 100,
    },
    {
        code: "702",
        key: "financial_commission",
        parentKey: "financial",
        text: "佣金结余",
        icon: "property-safety",
        path: "/financial_commission",
        order: 100,
    },
    {
        code: "703",
        key: "financial_income",
        parentKey: "financial",
        text: "收益管理",
        icon: "reconciliation",
        path: "/financial_income",
        order: 100,
    },
    {
        code: "704",
        key: "financial_payment",
        parentKey: "financial",
        text: "付款管理",
        icon: "insurance",
        path: "/financial_payment",
        order: 100,
    },
    {
        code: "705",
        key: "financial_incomeOrder",
        parentKey: "financial",
        text: "收款单明细",
        icon: "reconciliation",
        path: "/financial_incomeOrder",
        order: 100,
    },
    {
        code: "706",
        key: "financial_refundOrder",
        parentKey: "financial",
        text: "退款单明细",
        icon: "insurance",
        path: "/financial_refundOrder",
        order: 100,
    },
    {
        code: "800",
        key: "consumer",
        text: "用户管理",
        icon: "idcard",
        order: 60098,
    },
    {
        code: "801",
        key: "consumer-list",
        parentKey: "consumer",
        text: "用户列表",
        icon: "user",
        path: "/consumer/list",
        order: 100,
    },

    {
        code: "801",
        key: "consumer-check",
        parentKey: "consumer",
        text: "内购链接",
        icon: "solution",
        path: "/consumer/check",
        order: 100,
    },
    {
        code: "900",
        key: "power",
        text: "权限管理",
        icon: "eye",
        order: 60098,
    },
    {
        code: "901",
        key: "role",
        parentKey: "power",
        text: "角色管理",
        icon: "solution",
        path: "/power_role",
        order: 100,
    },
    {
        code: "902",
        key: "starff",
        parentKey: "power",
        text: "员工管理",
        icon: "usergroup-add",
        path: "/power_starff",
        order: 100,
    },


    // { key: 'addEdit', parentKey: 'starff', text: '新增/查看/编辑成员', icon: 'idcard', path: '/power_starff/addEdit',target: '_blank', order: 100},

    // {key: 'antDesign', text: 'Ant Design 官网', icon: 'ant-design', url: 'https://ant-design.gitee.io', target: '', order: 2000},
    // {key: 'document', text: '文档', icon: 'book', url: 'http://shubin.wang/docs', target: '_blank', order: 1200},
    // {key: 'customer-header', text: '自定义头部', icon: 'api', path: '/example/customer-header', order: 998},
    // {key: 'user', text: '用户管理', icon: 'user', path: '/users', order: 900},
    // {key: 'products', text: '商品管理', icon: 'user', path: '/products', order: 10000},
    // {key: 'role', text: '角色管理', icon: 'lock', path: '/roles', order: 900},
    // {key: 'menu', text: '菜单管理', icon: 'align-left', path: '/menu-permission', order: 900},
    // {key: 'page404', text: '404页面不存在', icon: 'file-search', path: '/404', order: 700},
];

/*
 * 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
 * */

export default function getMenus(userId) {
    return ajax.post('/oper/managerRole/getManagerPermissionCodeList?managerId=' + userId, {}, { errorTip: false }).then(res => {
        if (res.code == 20000) {
            let menus = res.data.list
            // let menusCode = menus.map((item)=>{return item.code })
            // 开发环境 携带开发者目录


            let _menusList = menusList.filter((item) => {
                return menus.indexOf(Number(item.code)) > -1
            })

            if (env == 'development') {
                _menusList.push(...deve_menuList)
            }

            localStorage.setItem('permission', JSON.stringify({ menu: _menusList }))

            return _menusList
        }
        return []
    });
}

// export default function getMenus(userId) {
//     // env == 'development' && menusList.push(developmentCode)

//     return Promise.resolve(menusList);
// }
