import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined} from '@ant-design/icons';
import {Icon} from 'src/library/components';
import {Popconfirm,  Button} from 'antd';
import './index.less';

/**
 * 操作封装，一般用于表格最后的操作列中
 */
export default class OperatorButton extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            label: PropTypes.isRequired,
            style: PropTypes.object,
            danger: PropTypes.bool,
            shape: PropTypes.string,
            visible: PropTypes.bool,
            disabled: PropTypes.bool,
            color: PropTypes.string,
            loading: PropTypes.bool,
            onClick: PropTypes.func,
            confirm: PropTypes.object,
        })),
        
    };

    static defaultProps = {
        items: [],
        
    };

    loadingIcon = <LoadingOutlined/>;

    label = {};

    getLabel = (options, i) => {
        let {type, label, icon, danger, shape, loading, style, disabled} = options;
        let buttonProps = {
            type,
            danger,
            disabled,
            shape
        }
        if (loading) {
            const labelWidth = this.label[i] ? this.label[i].offsetWidth : 'auto';
            return <Button className="operator-label" style={{display: 'inline-block', width: labelWidth, textAlign: 'center'}}>{this.loadingIcon}</Button>;
        }

        const labelStyle = disabled?{}:{
            transition: 'all 1ms', // 解决拖拽表格，点击无效问题
            ...style
        };


        return <Button 
                    {...buttonProps}
                    style={labelStyle} 
                    ref={v => this.label[i] = v}
                    icon={icon ? <Icon type={icon}/> : ""}
                    >{label}</Button>;
    };

    /*
     * 如果含有confirm属性，即表明是Popconfirm，
     * confirm作为Popconfirm的props
     *
     * 其他元素同理
     * */
    getConfirm = (options, i) => {
        let label = this.getLabel(options, i);
        const {confirm} = options;

        
        return (
            <Popconfirm okType="danger" {...confirm}>
                {label}
            </Popconfirm>
        );
    };

   
    getButton = (options, i) => {
        let label = this.getLabel(options, i);
        const {onClick} = options;

        return <span onClick={onClick}>{label}</span>;
    };

    getItem = (opt, i) => {
        const {
            confirm,
            visible = true,
            disabled = false,
        } = opt;

        if (visible) {
           

            if (disabled) {
                opt.color = '#ccc';
                return this.getLabel(opt, i);
            }

            if (confirm) return this.getConfirm(opt, i);

           

            return this.getButton(opt, i);
        }
        return null;
    };

    render() {
        let {items} = this.props;
        let operators = [];
        

        items.forEach((opt, i) => {
            const item = this.getItem(opt, i);

            if (item) {
                operators.push(item);
            }
        });

        

        const operatorsLength = operators.length;

        if (!operatorsLength) {
            return null;
        }

        return (
            <div style={{marginTop: '8px'}}>
                {operators.map((v, i) => (
                    <span key={v.label || `operator-${i}`}>
                        {v}
                        {operatorsLength === i + 1 ? '' : <span className="operator-block"/>}
                    </span>
                ))}
            </div>
        );
    }
}
