import React from "react";
import { Button, Form, Row, Col } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.less";
import { IsPC } from "src/library/utils/tools";

export default class QueryFormBar extends React.Component {
    static propTypes = {
        form: PropTypes.any,
        collapsed: PropTypes.bool,          // 展开/收起 状态
        onCollapsedChange: PropTypes.func,  // 展开/收起 状态改变
        children: PropTypes.any
    };

    static defaultProps = {
        onCollapsedChange: collapsed => collapsed,
        form: {},
        children: [],
        onFinish: () => {
        },
        onReset:()=>{}
    };

    state = {
        showCollapsed: false, // 是否展示折叠按钮
        collapseNum: 1, // 折叠 显示 一个
        collapsed: false, // 是否折叠
        notCollapsedFirstCollapseNum: 1, // 不折叠情况下第一行显示列数
        collapseMinWidth: 300, // 每一列最小宽度
        buttonCollapseMinWidth: 200, // 按钮区域最小宽度
        buttonCollapseMinWidthSetting: 200, // 按钮区域实际宽度
    };

    componentDidMount() {
        this.commonFunction();
        window.onresize = () => {
            this.commonFunction();
        };
    }

    handleCollapsedChange = (e) => {
        e.preventDefault();
        

         // 页面内容有改动，页面中有可能有撑满全屏的元素，需要调整
        // 切换时，滚动条会有闪动，需要调整body的overflow
        const oldOverflow = document.body.style.overflow;
        document.body.style.overflow = "hidden";
        

        const { collapsed } = this.state;
        this.setState({ collapsed: !collapsed },()=>{
            this.commonFunction();//展开时，重新计算collapseNum
            setTimeout(() => {
                window.dispatchEvent(new Event("resize"));
                document.body.style.overflow = oldOverflow;
            });
        });


       

       

        // console.log('=====>', collapsed )

       
    };

    commonFunction = () => {
        let isPcMode = IsPC();
        // if (isPcMode) {
            const { collapsed, buttonCollapseMinWidth, collapseMinWidth } = this.state;
            const { children = [] } = this.props;
            const docWidth = document.body.clientWidth; // 页面宽度
            const docPaddingLeft = parseFloat(document.body.style.paddingLeft); // 左侧菜单栏宽度
            // 折叠时,只显示一行
            if (!collapsed) {
                const collapseNum = isPcMode  ? Math.floor((docWidth - docPaddingLeft - buttonCollapseMinWidth) / collapseMinWidth):Math.ceil((docWidth - docPaddingLeft - buttonCollapseMinWidth) / collapseMinWidth);
                const showCollapsed = children.length > collapseNum;
                const buttonCollapseMinWidthSetting = buttonCollapseMinWidth;
                this.setState({ collapseNum, showCollapsed, buttonCollapseMinWidthSetting });
            } else { // 不折叠，显示多行
                let notCollapsedFirstCollapseNum = Math.floor((docWidth - docPaddingLeft) / collapseMinWidth);
                let collapseNum = isPcMode  ? Math.round((docWidth - docPaddingLeft - buttonCollapseMinWidth) / collapseMinWidth):Math.ceil((docWidth - docPaddingLeft - buttonCollapseMinWidth) / collapseMinWidth);
                let showCollapsed = children.length > collapseNum;
                if (showCollapsed) { // 显示折叠按钮，说明需要多行展示
                    // this.setState({ notCollapsedFirstCollapseNum, buttonCollapseMinWidthSetting: collapseMinWidth, showCollapsed ,collapseNum});
                    this.setState({ notCollapsedFirstCollapseNum, buttonCollapseMinWidthSetting: buttonCollapseMinWidth, showCollapsed ,collapseNum});
                } else { // 不显示折叠按钮，说明一行可是展示完
                    collapseNum = Math.floor((docWidth - docPaddingLeft - buttonCollapseMinWidth) / collapseMinWidth);
                    showCollapsed = isPcMode ? children.length > collapseNum : true;
                    this.setState({
                        collapseNum,
                        showCollapsed,
                        collapsed: showCollapsed !== false,
                        notCollapsedFirstCollapseNum,
                        buttonCollapseMinWidthSetting: buttonCollapseMinWidth
                    });
                }
            }
        // }
    }

    render() {
        const {
            className,
            onCollapsedChange,
            form,
            onFinish,
            onReset,//重置
            children,
            ...others
        } = this.props;
        const { showCollapsed, collapseNum, notCollapsedFirstCollapseNum, collapseMinWidth, collapsed, buttonCollapseMinWidthSetting } = this.state;
        let newChildren = Array.isArray(children) ? children : [children];
        let _children = newChildren.slice(0, collapseNum);
        let hiddenChild = newChildren.slice(collapseNum,newChildren.length);
        let _childrenEmpty;
        if (collapsed) {
            _childrenEmpty = [];
            let empty = notCollapsedFirstCollapseNum - (newChildren.length % notCollapsedFirstCollapseNum);

            if (empty === 0) {
                _childrenEmpty.push("empty");
            } else {
                while(empty > 0) {
                    _childrenEmpty.push("empty");
                    empty --;
                }
            }
        }

        let defaultHeight ,collapseHeight;
        let line = newChildren.length / collapseNum;
        if(IsPC()){
            defaultHeight = '44px';//默认父元素高度
            collapseHeight = collapsed ? Math.ceil(newChildren.length % collapseNum == 0 ? line +1 :line)*44 + 'px':defaultHeight;//浮动部分计算高度
        }else{
            defaultHeight = '88px';
            collapseHeight = collapsed ? Math.ceil(line + 1)*44 + 'px':defaultHeight;//浮动部分计算高度
        }

        let wrapStatus = '';

        if (showCollapsed && collapsed) {
            wrapStatus = 'sx-query-bar-collapsed';
        }

        // console.log('_children', Math.ceil(line + 1),newChildren.length, collapseNum)
        return (
            <div
                className={classNames(className, "sx-query-bar", wrapStatus)}
                style={{height:defaultHeight,position:'relative'}}
                {...others}
            >
                <Form
                    name="product-query"
                    colon={false}
                    form={form}
                    onFinish={onFinish}
                    style={{
                        position:'absolute',
                        left:'0px',
                        top:'0px',
                        width:'100%',
                        backgroundColor:'#fff',
                        zIndex:10,
                        borderBottom: '1px solid rgb(238, 238, 238)',
                        boxShadow: 'rgb(238 238 238) 0px 4px 8px',
                        borderRadius: '5px',
                        // height:collapseHeight
                    }}
                >
                    <Row
                        gutter={16}
                        justify="space-around"
                        style={{marginRight: 0, justifyContent: 'flex-start'}}
                    >
                        {_children && _children.map((item, index) => {
                            if(!item){
                                return
                            }
                            return <Col key={`child-${index}`} style={{
                                flex: "1 0",
                                flexBasis: item.props.minWidth? item.props.minWidth: collapseMinWidth,
                                maxWidth: 1.5 * collapseMinWidth,
                            }}>{item}</Col>;
                        })}
                        
                        {/* 隐藏部分展示 */}
                        { hiddenChild.map((item, index) => {
                            if(!item){
                                return
                            }
                            return <Col key={`child-${index}`} style={{
                                flex: "1 0",
                                display:collapsed ?'block':'none',
                                flexBasis: collapseMinWidth,
                                maxWidth: 1.5 * collapseMinWidth,
                            }}>{item}</Col>;
                        })}
                        {_childrenEmpty && _childrenEmpty.map((item, index) => {
                            return <Col key={`empty-${index}`} style={{ flex: "1 0", flexBasis: collapseMinWidth, padding: "0 15px", height: 37 }} />;
                        })}
                        <div className="form-handler-wrap" style={{
                            display: "flex",
                            flex: "1 1",
                            flexBasis: buttonCollapseMinWidthSetting,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginBottom: 8
                        } }>
                            <Button type="primary" style={ { marginRight: 10 } } onClick={()=>{this.setState({collapsed:false},_=>this.commonFunction());}} htmlType="submit">查询</Button>
                            <Button style={ { marginRight: showCollapsed ? 10:18 } } onClick={ () => {this.setState({collapsed:false},_=>this.commonFunction());onReset()}}>重置</Button>
                            {
                                showCollapsed ? (
                                    <a style={{ marginRight: 30 }} onClick={this.handleCollapsedChange}>
                                        { collapsed ? "收起" : "更多"}
                                    </a>
                                ) : null
                            }
                        </div>
                    </Row>
                </Form>
            </div>
        );
    }
}
