import React, { Component } from "react";
import { Button,Modal } from "antd";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";
import Upload from "../upload-video"
// import Upload from "../upload"
import classNames from "classnames";
import "./style.less";
import delBtn from "./img/delete.png"
import playBtn from "./img/play.png"
import downloadBtn from "./img/download.png"
import videoDown from "../upload-video/video.png"
import CoverDown from "./img/coverDown.png"

const SortableItem = SortableElement((props) => {
    const {
        item,
        index,
        onMouseEnter,
        onMouseLeave,
        onRemove,
        onPreview,
        onDownload,
        showMode,
        activeInfo,
    } = props;

    let { videoUrl,  videoCover} = item || {}
    return (
        <div className="videoItem globle">
            <div
                className="videoBox"
                onMouseEnter={() => {
                    onMouseEnter();
                }}
                onMouseLeave={() => {
                    onMouseLeave();
                }}
            >
            <div className='video-upload-end'>
                
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',borderadius: '10px'}}>
                   <img className="img" src={videoCover} alt="" />
                    {/* icon按钮 */}
                    <div className={`operBox ${ activeInfo == index ? "active" : "" }`}>
                       {/* 播放 */}
                        <Button
                        className="iconBtn"
                        onClick={() => onPreview(item)}
                        >
                            <img src={playBtn} width={24}/>
                        </Button>
                        {/* 下载 */}
                       
                        <Button
                            className="iconBtn"
                            onClick={() => {
                                onDownload()
                            }}
                        >
                            <img src={downloadBtn} width={24}/>
                        </Button>
                       
                         {/* 删除 */}
                        {!showMode && (
                            <Button className='iconBtn' 
                            onClick={() => {
                                onRemove();
                            }}>
                            <img src={delBtn} width={24}/>
                        </Button>
                        )}
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
});

const SortableContainerList = SortableContainer((props) => {
    const {
        dataSource,
        isSorting,
        onMouseEnter,
        onMouseLeave,
        onPreview,
        onRemove,
        onDownload,
        showMode,
        children,
        className,
        ...others
    } = props;

    return (
        <div
            className={classNames(`videoGroup ${className}`, {
                sorting: isSorting,
            })}
        >
            {dataSource &&
                dataSource.map((item, index) => {
                    return (
                        <SortableItem
                            key={"key_" + index}
                            item={item}
                            index={index}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onPreview={onPreview}
                            onRemove={onRemove.bind(this,index)}
                            onDownload={onDownload.bind(this,index)}
                            showMode={showMode}
                            {...others}
                        ></SortableItem>
                    );
                })}
              

            {!showMode && <div style={{width: '100%' ,height: '100%',display:'flex',alignItems:'center'}}>{children}</div>}
        </div>
    );
});

class UploadVideoList extends Component {
    state = {
        itemLength: 0,
        isSorting: false,
        value:[],
    };

    componentDidMount(){
        this.setState({value:[...this.props.value]})
    }

    //查看video
    async handlePreview (item){
        this.setState({
            previewVideo: item.videoUrl,
            previewVisible: true
        })
    };
    //关闭查看框
    handleCancel ()  {
        var video = document.getElementById('Video');
        if(video.play){
            video.pause();
        }
        this.setState({ previewVisible: false })
    };

    //下载video和cover
    handleDownload(index){
        this.setState({
            downloadIndex: index,
            downloadVisible: true
        })
    }
    //关闭下载框
    handleClose(){
        this.setState({downloadVisible:false})
    }
    //下载 tag——1：下载视频 2：下载封面
    handleDownloadClick(tag,value){
        let { downloadIndex } = this.state;

        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download =
            parseInt(Math.random() * 1000) || "photo";
        a.href = tag == 1 ? value[downloadIndex].videoUrl :value[downloadIndex].videoCover ;
        a.target = "_blank";
        a.dispatchEvent(event);
        a.remove()
        this.setState({downloadVisible:false,downloadIndex:-1})
    }

    onSortStart = (info, event) => {
        const { showMode } = this.props;
        this.setState({ isSorting: !showMode || true });
    };

    onSortEnd = (info, event) => {
        this.setState({ isSorting: false });
        let { newIndex, oldIndex } = info;

        const { value, onSortEnd } = this.props;
        let _fileList = arrayMove([...value], oldIndex, newIndex);

        if (onSortEnd) {
            onSortEnd(_fileList);
        }
    };

   
    onMouseEnter(index) {
        this.setState({ activeInfo: index });
    }
    onMouseLeave() {
        this.setState({ activeInfo: -1 });
    }
    //上传结束
    onUploadChange(item){
        if(!item){
            return
        }
        let {value} = this.props
        let _value = [...value]
        _value.push(item);
        this.props.onChange && this.props.onChange(_value)
    }
    //删除
    onDelete(index){
        let {value} = this.props
        let _value = [...value]
        _value.splice(index,1)
        this.props.onChange && this.props.onChange(_value)
    }

    render() {
        const {
            value = [],
            maxNum,
            showMode,
            className,
            ...others
        } = this.props;
        const { isSorting, activeInfo , previewVisible, previewVideo, downloadVisible } = this.state;

        const props = {
            isSorting,
            dataSource: value,
            activeInfo,
            onSortEnd: this.onSortEnd,
            onSortStart: this.onSortStart,
            onMouseEnter: this.onMouseEnter.bind(this),
            onMouseLeave: this.onMouseLeave.bind(this),
            onPreview: this.handlePreview.bind(this),
            onRemove: this.onDelete.bind(this),
            showMode,
            onDownload:this.handleDownload.bind(this),
            // showDown,
            axis: "x",
            distance: 1,
            ref: "component",
            // children,
            className,
            ...others
        };

        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                }}
                ref={(node) => (this.container = node)}
            >
                <SortableContainerList {...props}>
                   { value.length <maxNum ?  <Upload onChange={this.onUploadChange.bind(this)}/> :null  }
                </SortableContainerList>
                {/* 预览 */}
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                    className="check-video-modal"
                >
                    <video id='Video' style={{height:'100%',width:'100%'}} controlsList="nodownload" controls src={previewVideo}></video>
                </Modal> 
                {/* 下载   */}
                <Modal
                title='请选择下载视频或封面'
                    visible={downloadVisible}
                    footer={null}
                    onCancel={this.handleClose.bind(this)}
                    className='download-modal'
                >
                    <div style={{display:'flex',justifyContent:'space-around'}}>
                        <Button className='download-item' onClick={()=>this.handleDownloadClick(1,value)}>
                            <img src={videoDown} width={20}/>
                            <span>下载视频</span>
                        </Button>
                        <Button className='download-item' onClick={()=>this.handleDownloadClick(2,value)}>
                            <img src={CoverDown} width={20}/>
                            <span>下载封面</span>
                        </Button>
                    </div>
                </Modal> 
            </div>
        );
    }
}
UploadVideoList.defaultProps = {
    value:[],
    maxNum:1,
    showMode: false,
    onChange:()=>{}
}
export default  UploadVideoList