import React from 'react';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import AppRouter from './router/AppRouter';
import {connect} from './models';
import moment from 'moment';
import 'moment/locale/zh-cn'; // 解决 antd 日期组件国际化问题
import { createFromIconfontCN } from '@ant-design/icons';
// 设置语言
moment.locale('zh-cn');



const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
    '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
  ],
});

@connect()
export default class App extends React.Component {
    componentWillMount() {
      let SIZE_WIDTH = 198
      for(let key of Object.keys(localStorage)){
        if(String(key).indexOf('side') > -1){
          let item = localStorage.getItem(key)
          item = JSON.parse(item)
          item.width = SIZE_WIDTH
          localStorage.setItem(key, JSON.stringify(item))
        }
      }
    }
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <AppRouter/>
            </ConfigProvider>
        );
    }

    
}
