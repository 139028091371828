import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Pagination} from 'antd';
import './index.less';
import {ToolBar } from '../../../library/components/index'
import { IsPC } from "src/library/utils/tools";

/**
 * 分页封装
 */
export default class PaginationComponent extends Component {
    static propTypes = {
        size: PropTypes.string,
        showSizeChanger: PropTypes.bool,
        showQuickJumper: PropTypes.bool,
        showMessage: PropTypes.bool,
        pageSize: PropTypes.number,
        pageNum: PropTypes.number,
        total: PropTypes.number,
        onPageNumChange: PropTypes.func,
        onPageSizeChange: PropTypes.func,
        child:PropTypes.array,//左侧按钮展示
        isRight:PropTypes.bool,
        tool_style:PropTypes.object
    };

    static defaultProps = {
        size: 'small',
        showSizeChanger: true,
        showQuickJumper: true,
        showMessage: true,
        pageSize: 15,
        pageNum: 1,
        total: 0,
        onPageNumChange: () => void 0,
        onPageSizeChange: () => void 0,
        child:[],
        isRight:false,
        tool_style:{},
        showPage:true,
    };
    handle_Scroll(){
        var tabBody = document.getElementsByClassName('ant-table-body');
        for(var i=0;i<tabBody.length;i++){
            tabBody[i].scrollTo(0,0);
        }
    }

    render() {
        const {
            size,
            showSizeChanger,
            showQuickJumper,
            showMessage,
            pageSize,
            pageNum,
            total,
            onPageNumChange,
            onPageSizeChange,
            child,
            isRight,
            tool_style,
            tool_btn_style,
            showPage,//是否展示分页
        } = this.props;

        const props = {};
        if (showSizeChanger) {
            props.showSizeChanger = true;
        }
        if (showQuickJumper) {
            props.showQuickJumper = true;
        }

        const totalPage = Math.ceil(total / pageSize);
        let style = this.props.style;
        if (total <= 0) {
            style = {/* display: 'none', */ ...style};
        }
        return (
            <div className="pagination-wrap" style={style}>
                {child ? <ToolBar
                    right={isRight}
                    children={child}
                    style={tool_style}
                    tool_btn_style={tool_btn_style}
                />:<div></div>
                }
                {showPage ? <div style={{flex: 1,display: 'flex',alignItems: 'center',justifyContent: 'flex-end'}}>
                   <Pagination
                    {...props}
                    simple={IsPC() ? false :true}
                    size={size}
                    pageSizeOptions={['10', '15', '20', '30', '50', '100']}
                    onShowSizeChange={(num, size) => {this.handle_Scroll(); onPageSizeChange(size)}}
                    onChange={(num) => { this.handle_Scroll(); onPageNumChange(num)}}
                    defaultCurrent={1}
                    pageSize={pageSize}
                    current={pageNum}
                    total={total}
                />
                
                {showMessage && IsPC()? (
                    <div className="total-count">
                        共{totalPage}页 {total}条数据
                    </div>
                ) : null} 
                </div>
                :<div></div>
                }
                
            </div>
        );
    }
}
