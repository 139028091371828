import React,{Component} from "react"

import { Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types';
import { getElementTop } from 'src/library/utils';
import { IsPC } from "src/library/utils/tools";
import "./style.less"

export default class HeadBtn extends Component{
    static propTypes = {
        btnChild: PropTypes.array,    //所有按钮
        leftContent:PropTypes.func,  //左侧展示内容
    }
    static defaultProps = {
    }
    state = {
        offsetT : (document.getElementsByClassName('no-print')[0].offsetHeight+5) + 'px',
    }
    render(){
        let { leftContent ,btnChild } = this.props;
        return <div id='detail' style={{top:IsPC() ? '8px' : this.state.offsetT}} ref = { v=> this.btn = v}>
        {leftContent}
        <div style={{display:'flex'}}>
            {
                Array.isArray(btnChild) && btnChild.map((item,index)=>{
                   return  <Button 
                    type={item.type} 
                    style={item.style?{...item.style,marginLeft:'10px'}:{marginLeft:'10px'}} 
                    onClick={()=>item.onClick()}>{item.text}</Button>
                })
            }
        </div>
        
    </div>
    }
}