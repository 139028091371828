import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { getElementTop } from 'src/library/utils';
import "./style.less"
import { IsPC } from "src/library/utils/tools";

export default class TableComponent extends Component {
    static propTypes = {
        surplusSpace: PropTypes.bool, // 是否使用剩余空间，如果 true 表格将铺满全屏
        serialNumber: PropTypes.bool, // 是否显示序号
        serialText: PropTypes.string, // 序号列表头名称
        pageSize: PropTypes.number,
        pageNum: PropTypes.number,
        otherHeight: PropTypes.number,
        offsetHeight: PropTypes.number,
        // path:PropTypes.any,
        bottomBtnHeight:PropTypes.any,//表格没有分页，表格底部有按钮高度

        // 其他antd属性列出便于IDE提示
        tableLayout: PropTypes.any,
        bordered: PropTypes.any,
        columns: PropTypes.any,
        components: PropTypes.any,
        dataSource: PropTypes.any,
        expandable: PropTypes.any,
        footer: PropTypes.any,
        loading: PropTypes.any,
        locale: PropTypes.any,
        pagination: PropTypes.any,
        rowClassName: PropTypes.any,
        rowKey: PropTypes.any,
        rowSelection: PropTypes.any,
        scroll: PropTypes.any,
        showHeader: PropTypes.any,
        size: PropTypes.any,
        summary: PropTypes.any,
        title: PropTypes.any,
        onChange: PropTypes.any,
        onHeaderRow: PropTypes.any,
        onRow: PropTypes.any,
        getPopupContainer: PropTypes.any,
        sortDirections: PropTypes.any,
        showSorterTooltip: PropTypes.any,
    };

    static defaultProps = {
        surplusSpace: true,
        pagination: false,
        serialText: '序号',
    };

    constructor(props){
        super(props)
    }
    
    state = {
        tableBodyHeight: 300,
    };
    //滚动条自定义，调整表头固定列宽度错位问题
    // handle_ScrollWidth(father){
    //     var child = document.createElement("th");
    //     child.className = 'ant-table-cell ant-table-cell-fix-right ant-table-cell-scrollbar lastTh';
    //     var thead = father.getElementsByClassName('ant-table-thead');
    //     for(var i=0;i<thead.length;i++){
    //         thead[i].firstChild.appendChild(child)
    //     }
    // }

    componentDidMount() {
        if (this.props.surplusSpace) {
            this.setTableBodyHeight();
            window.addEventListener('resize', this.setTableBodyHeight);
        }
    };

    componentDidUpdate(prevProps) {
        const { path,dataSource } = this.props;
        // if(path){
        //     const father = document.getElementById(path).getElementsByClassName('ant-tabs-tabpane-active')[0] ? document.getElementById(path).getElementsByClassName('ant-tabs-tabpane-active')[0] :document.getElementById(path);
        //     if(IsPC()){
        //         if(path && Array.isArray(dataSource) && dataSource.length == 0 && !father.getElementsByClassName('lastTh')[0]){
        //             this.handle_ScrollWidth(father);
        //         }
        //     }else{
        //         if(path && !father.getElementsByClassName('lastTh')[0]){
        //             this.handle_ScrollWidth(father);
        //         }
        //     }  
        // }
        
        
        if (this.props.surplusSpace) {
            const prevDataSource = prevProps.dataSource;
            const { dataSource } = this.props;

            if (dataSource?.length !== prevDataSource?.length) {
                this.setTableBodyHeight();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.surplusSpace) {
            window.removeEventListener('resize', this.setTableBodyHeight);
        }
    }

    setTableBodyHeight = () => {
        this.tableBody = this.wrapper.querySelector('.ant-table-tbody');
        this.tablePlaceholder = this.wrapper.querySelector('.ant-table-placeholder');
        this.tableHead = this.wrapper.querySelector('.ant-table-header');
        this.tableThead = this.wrapper.querySelector('.ant-table-thead');//无数据时表头

        const { pathname, search } = window.location;
        const currentPath = window.decodeURIComponent(`${pathname}${search}`);
        const activeTab = document.getElementById(currentPath);
        this.pagination = (activeTab ? activeTab : document).querySelector('.pagination-wrap');

        let tableBodyHeight;
        const { dataSource, noData = true ,bottomBtnHeight} = this.props;
        const windowHeight = document.documentElement.clientHeight;

        // 计算除了表格内容之外，其他高度
        if ('otherHeight' in this.props) {
            const { otherHeight } = this.props;
            tableBodyHeight = windowHeight - otherHeight;
        } else {
            var tableHeadHeight = 37 , paginationHeight = 0;
            // var tableHeadHeight = 0 , paginationHeight = 0;
            if(IsPC()){//PC端
                // tableHeadHeight = noData && this.tableThead ? this.tableThead.offsetHeight: this.tableHead ? this.tableHead.offsetHeight + 1 : 0;
                paginationHeight = this.pagination ? this.pagination.offsetHeight==0 || this.pagination.offsetHeight > 36 ? 38:  this.pagination.offsetHeight: bottomBtnHeight ? bottomBtnHeight : 0
            }else{
                // tableHeadHeight = dataSource?.length ? 36 : 0;
                paginationHeight = this.pagination ? this.pagination.offsetHeight==0 ? 36 :this.pagination.offsetHeight : bottomBtnHeight ? bottomBtnHeight : 0;
            }
            // const tableHeadHeight = this.tableHead?.offsetHeight + 1 || 0 ;
            const bottomHeight = paginationHeight + 10 + 10;

            const tableOffsetTop = getElementTop(this.wrapper) + 1;
            const otherHeight = tableOffsetTop + tableHeadHeight + bottomHeight;

            tableBodyHeight = windowHeight - otherHeight + 4;

            if ('offsetHeight' in this.props) tableBodyHeight = tableBodyHeight - this.props.offsetHeight;
        }

        if (dataSource?.length) {
            if(tableBodyHeight < 100){
                tableBodyHeight = 200;
                // this.tableBody.style.height = '200px';
            }else{this.tableBody.style.height = this.tableBody.offsetHeight > tableBodyHeight ?`${tableBodyHeight}px`:'0px';}
            
        } else {
            this.tableBody.style.height = '0px';
            const {otherHeight } = this.props;
            if (noData) {
                this.tablePlaceholder.style.height = otherHeight && otherHeight > 400 ? 250+'px' : `${tableBodyHeight-10}px`;
                if(tableBodyHeight < 100){
                    this.tablePlaceholder.style.height = '200px'
                }
            }
        }

        this.setState({ tableBodyHeight });
    };

    render() {
        let {
            scroll = {},
            pagination,
            surplusSpace,
            serialNumber,
            serialText,
            // 分页属性

            pageSize,
            pageNum,

            rowSelection,
            columns,
            ...others
        } = this.props;
        const { tableBodyHeight } = this.state;
        let tableScroll = surplusSpace ? { y: tableBodyHeight, ...scroll } : scroll;

        if (rowSelection === true) rowSelection = {};

        if (!rowSelection) rowSelection = void 0;

        if (serialNumber) {
            if (this.pagination) {
                if (!('pageNum' in this.props)) console.error('分页表格如果显示序号，需要传递pageNum属性');
                if (!('pageSize' in this.props)) console.error('分页表格如果显示序号，需要传递pageSize属性');
            }

            columns = [
                {
                    title: serialText,
                    width: 50,
                    dataIndex: '__num',
                    key: '__num',
                    align:'left',
                    render: (value, record, index) => {
                        if (this.pagination) {
                            return (index + 1) + pageSize * (pageNum - 1);
                        } else {
                            return index + 1;
                        }
                    },
                },
                ...columns,
            ];
        }

        return (
            <div  className='table' ref={node => this.wrapper = node}>
                <Table
                    scroll={tableScroll}
                    pagination={false}
                    rowSelection={rowSelection}
                    {...others}
                    columns={columns}
                    // style={{minHeight:'100px'}}
                />
            </div>
        );
    }
}
